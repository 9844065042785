<template>
  <!--  文章管理-->
  <div class="activeManager">

    <a-spin :spinning="spinning">
      <div class="search">
        <div>
          作者: &nbsp;<a-input v-model="searchName" placeholder="请输入" size="default"
                             style="width: 180px" @pressEnter="()=>this.searchOk()"/>&nbsp;&nbsp;&nbsp;
          标题: &nbsp;<a-input v-model="searchTitle" placeholder="请输入" size="default"
                             style="width: 180px" @pressEnter="()=>this.searchOk()"/>&nbsp;&nbsp;&nbsp;
          栏目: &nbsp;<a-select v-model="searchType" style="width: 120px" @change="()=>this.searchOk()">
          <a-select-option v-for="(codes,index) in codeList" :key="index" :value="codes.title">
            {{codes.title}}
          </a-select-option>
        </a-select>&nbsp;&nbsp;&nbsp;
          <a-button style="margin-left: 10px" type="primary" @click="searchOk">查询</a-button>
          <a-button style="margin-left: 10px" @click="searchReset">重置</a-button>
        </div>
      </div>

      <div style="display: flex;justify-content: space-between;">
        <div class="menu">
          <a-menu
              v-model="menu"
              mode="inline"
              style="width: 100%;"
          >
            <a-menu-item
                v-for="(item,index) in typeInfo"
                :key="index"
                @click="leftChange(index)"
            >
              {{ item.title }}
            </a-menu-item>
          </a-menu>
        </div>
        <div class="center">
          <a-button type="primary" @click="addBtn">新增</a-button>
          <a-table :columns="columns"
                   :data-source="tableInfo"
                   :pagination="false" rowKey="id" style="margin-bottom: 10px;min-height: 420px;margin-top: 10px"
          >
            <span slot="titles" slot-scope="text,item">
              <span style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ text }}</span>
            </span>
            <span slot="expert" slot-scope="text,item">
              <span  v-if="text">{{text}}</span>
              <span v-else> </span>
            </span>
            <span slot="sort" slot-scope="text,item">
              <a style="margin-right: 10px" @click="move('up',item.id)">上移</a>
              <a @click="move('down',item.id)">下移</a>
            </span>

            <span slot="operate" slot-scope="text,item">
              <a style="margin-left: 10px"  >推广</a>
              <a style="margin-left: 10px" @click="editBtn(item)">修改</a>
              <a-popconfirm
                  style="margin-left: 10px"
                  title="确定删除?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="delTable(item.id)"
              >
            <a >删除</a>
            </a-popconfirm>
            </span>
          </a-table>
          <div style="display: flex;justify-content: flex-end;right: 62px;bottom: 3.5rem;z-index: 3">
            <a-pagination v-model="pagination.current"
                          :total="pagination.total"
                          @change="pageChange"/>
          </div>

        </div>
      </div>
<!-------------------------------弹框----------------------------------------->
      <a-drawer
          :title="addTitle"
          :visible="addShow"
          width="600"
          @close="()=>{this.addShow = false;}"
      >
        <a-form-model
            ref="addItem"
            :label-col="labelCol"
            :model="addForm"
            :rules="addOrRest_rules"
            :wrapper-col="wrapperCol">
          <a-form-model-item prop="templateCode" label="选择样式">
            <a-select v-model="addForm.templateCode" @change="styleType">
              <a-select-option value="T_BOTTOM_1">
                样式一
              </a-select-option>
              <a-select-option value="T_BOTTOM_3">
                样式二
              </a-select-option>
              <a-select-option value="T_RIGHT_1">
                样式三
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <img :src="styletype==='T_BOTTOM_1'? wenStyle1:styletype==='T_BOTTOM_3'? wenStyle2 :styletype==='T_RIGHT_1'? wenStyle3:''"
                 alt=""
                 style="width: 50%;margin-left: 25%">
          </a-form-model-item>
          <a-form-model-item prop="cover" label="图片">
            <div style="width: 450px;">
              <div class="uploadImg" >
                <input
                    id="up_img"
                    style="width: 100%;height:100%;opacity: 0;"
                    accept="image/*"
                    type="file"
                    @change="uploadImg"
                    ref="uploadImg_image" />
              </div>
              <div style="color: #a1a1a1">注：尺寸 640*360，大小不超过500kb</div>
              <div
                  class="upload_gutter">
                <img
                    v-if="addForm.cover[0]"
                    alt=""
                    style="width: 100%;height:100%"
                    :src="addForm.cover[0]"
                />
              </div>
            </div>

          </a-form-model-item>
          <a-form-model-item prop="title" label="标题">
            <a-input v-model="addForm.title"/>
          </a-form-model-item>
          <a-form-model-item label="副标题">
            <a-input v-model="addForm.intro"/>
          </a-form-model-item>
          <a-form-model-item prop="url" label="跳转链接">
            <a-input v-model="addForm.url"/>
          </a-form-model-item>
          <a-form-model-item label="作者">
            <a-select
                @popupScroll="popupScroll_specialist"
                @search="select_specialist"
                v-model="addForm.expertId"
                show-search
                placeholder="请选择作者"
                option-filter-prop="children"
                style="width: 370px"
            >
              <a-select-option v-for="(special,inds) in specialistList" :key="inds" :value="special.id">
                {{ special.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="columnCode" label="所属栏目">
            <a-select v-model="addForm.columnCode">
              <a-select-option v-for="(lists,ind) in codeList" :key="ind" :value="lists.code">
                {{lists.title}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="type" label="分类">
            <a-select v-model="addForm.type">
              <a-select-option value="ARTICLE_SINGLE">
                文章
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>

        <div
            :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
        >
          <a-button style="marginRight: 8px" @click="()=>{this.addShow = false;this.addForm={}}">
            取消
          </a-button>
          <a-button type="primary" @click="addType=== 'add'?addOk():editOk()">
            确认
          </a-button>
        </div>
      </a-drawer>
      <a-drawer
          title="推广"
          :visible="promoteShow"
          width="600"
          @close="()=>{this.promoteShow = false}"
      >
        <a-table :columns="appColumns" style="margin-bottom: 35px" :data-source="appInfo" :pagination="false" rowKey="id"
        >
      <span slot-scope="state" slot="text,item">
         <a-switch checked-children="是" default-un-checked un-checked-children="否" @change="stateSwitch"/>
      </span>


        </a-table>
        <a-table :columns="communityColumns" style="margin-bottom: 35px" :data-source="communityInfo" :pagination="false" rowKey="id"
        >
      <span slot-scope="state" slot="text,item">
         <a-switch checked-children="是" default-un-checked un-checked-children="否" @change="stateSwitch"/>
      </span>


        </a-table>



      </a-drawer>

    </a-spin>
  </div>
</template>

<script>
import {update} from "@/utils/update";
import {
  del_active_list,
  get_active_list,
  get_column_vods,
  get_experts_id,
  move_active_list, post_active_list, put_active_list
} from "@/service/MedicalConference_y";

export default {
  name: "activeManager",
  data() {
    return {
      spinning: false,
      addShow: false,
      editShow: false,
      addTitle: '',
      addType: '',
      menu: [0],
      labelCol: {span: 4},
      wrapperCol: {span: 16},
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      searchName: '',
      searchTitle: '',
      searchType: '',
      styletype: 'T_BOTTOM_1',
      columns: [
        {
          title: '标题',
          dataIndex: 'title',
          key: 'title',
          scopedSlots: {customRender: 'titles'},
        },
        {
          title: '作者',
          dataIndex: 'expert',
          key: 'expert',
          width:'20%',

          scopedSlots: {customRender: 'expert'},
        },
        {
          title: '排序',
          scopedSlots: {customRender: 'sort'},
        },
        {
          title: '操作',
          scopedSlots: {customRender: 'operate'},
        },],
      tableInfo: [],
      codeList: [],
      typeInfo: [
        {
          title: '全部'
        },
        {
          title: '文章'
        }
      ],
      wenStyle1: require('/src/assets/wen style1.png'),
      wenStyle2: require('/src/assets/wen style2.png'),
      wenStyle3: require('/src/assets/wen style3.png'),
      promoteShow:false,
      appColumns:[
        {
          title: 'App首页',
          dataIndex: 'title',
        },
        {
          title: '状态',
          scopedSlots: {customRender: 'state'},
        },
      ],
      appInfo:[],
      communityColumns:[
        {
          title: '首页',
          dataIndex: 'title',
        },
        {
          title: '状态',
          scopedSlots: {customRender: 'state'},
        },
      ],
      communityInfo:[
        {
          title:'首页'
        }
      ],
      data:[],
      addForm: {
        templateCode:'T_BOTTOM_1',
        cover:[],
        title:'',
        intro:'',
        url:'',
        expertId:undefined,
        columnCode:undefined,
        type:undefined,
      },
      addOrRest_rules:{
        templateCode: [{ required: true, message: '请选择样式', trigger: 'blur' }],
        cover: [{ required: true, message: '请上传图片', trigger: 'blur' }],
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        url: [{ required: true, message: '请输入跳转链接', trigger: 'blur' }],
        columnCode: [{ required: true, message: '请选择所属栏目', trigger: 'blur' }],
        type: [{ required: true, message: '请选择分类', trigger: 'blur' }],
      },
      specialistList:[],
      specialist_page_no:1,
      specialist_title:'',
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle','文章管理')
  },
  mounted() {
    this.getList()
    this.getCodeList()
  },
  methods: {
    async getList() {
      this.spinning = true
      const response = await get_active_list(this.searchName,this.searchTitle,this.searchType,this.pagination.current,10)
      if(response.code === 0){
        this.tableInfo = response.data
        this.pagination.total = response.count
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    },
    //获取专家列表
    async getExpertsId(name,pageNum,pageSize) {
      const response = await get_experts_id(name,pageNum,pageSize)
      if(response.code === 0){
        let list  = response.data
        for (let i=0;i<list.length;i++){
          this.specialistList.push({
            id: list[i].id,
            name: list[i].name,
          })
        }
      }else {
        this.$message.warning(response.message)
      }
    },
    // //滚动条监听
    popupScroll_specialist(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.specialist_page_no++
        this.getExpertsId(this.specialist_title,this.specialist_page_no)
      }
    },
    // //鼠标搜索
    select_specialist(value){
      //首先清空数组，否则会数据重复
      this.specialistList = []
      this.specialist_title = value
      this.getExpertsId(value,1)
    },
    async getCodeList() {
      let data = {
        type:'vod',
      }
      const response = await get_column_vods(data)
      if(response.code === 0){
        this.codeList = response.data
      }else {
        this.$message.warning(response.message)
      }
    },
    searchOk() {
      this.getList()
    },
    searchReset() {
      this.searchName = ''
      this.searchTitle = ''
      this.searchType = ''
      this.pagination.current = 1
      this.getList()
    },
    pageChange(pageNum) {
      this.pagination.current = pageNum
      this.getList()
    },
    leftChange() {
    },
    async move(move, id) {
      const response = await move_active_list(id,move)
      if (response.code === 0) {
        this.$message.success("操作成功~")
        await this.getList()
      } else {
        this.$message.warning(response.message)
      }
    },
    // 推广确认btn
    stateSwitch(){

    },
    //上传图片
    async uploadImg() {
      let file = this.$refs.uploadImg_image.files[0]
      if (file !== undefined) {
        if (file.size < 512000) {
          const image = await update(file);
          if (image.code === 0) {
            this.addForm.cover[0] = image.data.url
            this.$message.success("上传成功")
            document.getElementById('up_img').value = null;
          } else {
            //  上传失败
            this.$message.error("上传失败，请重试。", image.message)
          }
        } else {
          this.$message.error("图片尺寸请小于2mb")
        }
      }
    },
    addBtn() {
      this.addType = 'add'
      this.addTitle = '新增'
      this.specialistList = []
      this.specialist_page_no = 1
      this.specialist_title = ''
      this.getExpertsId()
      this.addShow = true
    },
    editBtn(item){
      this.addType = 'rest'
      this.addTitle = '修改'
      this.styletype = item.templateCode
      this.specialistList = []
      this.specialist_page_no = 1
      this.specialist_title = ''
      this.getExpertsId()
      //赋值
      this.addForm = item
      this.addShow = true
    },
    styleType(value) {
      this.styletype = value
    },
    async addOk() {
      this.$refs.addItem.validate(async valid => {
        if (valid) {
          let form = this.addForm
          let data = {
            templateCode: form.templateCode,    //样式一：T_BOTTOM_1,样式二:T_BOTTOM_3,样式三:T_RIGHT_1
            cover: form.cover,   //图片连接
            title: form.title, //标题
            intro: form.intro,   //副标题，可以为空
            url: form.url,  //跳转连接
            expertId: form.expertId,     //作者id
            columnCode: form.columnCode, //栏目代码code
            type: form.type //文章分类：ARTICLE_SINGLE
          }
          const response = await post_active_list(data)
          if (response.code === 0) {
            this.$message.success("操作成功~")
            await this.getList()
            //  清空
              this.$refs.addItem.resetFields()
            form.intro = ''
            form.expertId = undefined
            this.addShow = false
          } else {
            this.$message.warning(response.message)
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    editOk() {
      this.$refs.addItem.validate(async valid => {
        if (valid) {
          let form = this.addForm
          let data = {
            "id":form.id,              //文章id
            "templateCode":form.templateCode,    //样式一：T_BOTTOM_1,样式二:T_BOTTOM_3,样式三:T_RIGHT_1
            "cover":form.cover,   //图片连接
            "title":form.title, //标题
            "intro":form.intro,   //副标题，可以为空
            "url":form.url,  //跳转连接
            "expertId":form.expertId,     //作者id
            "columnCode":form.columnCode, //栏目代码code
            "type":form.type //文章分类：ARTICLE_SINGLE

          }
          const response = await put_active_list(data)
          if (response.code === 0) {
            this.$message.success("操作成功~")
            await this.getList()
            //  清空
            this.$refs.addItem.resetFields()
            form.intro = ''
            form.expertId = undefined
            this.addShow = false
          } else {
            this.$message.warning(response.message)
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async delTable(id) {
      const response = await del_active_list(id)
      if (response.code === 0) {
        this.$message.success("操作成功~")
        await this.getList()
      } else {
        this.$message.warning(response.message)
      }
    },
    cancel(){
      this.$message.warning('取消删除');
    },
    //删除图片数组
    delImg(index) {
      this.addForm.picture.splice(index, 1)
    },
  }
}
</script>

<style lang="scss" scoped>
.activeManager {
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);

  .search {
    background-color: white;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 15px;
    margin-bottom: 1%;
  }

  .menu {
    background-color: white;
    width: 14%;
    height: 100%;
    margin-bottom: -1000px;
    padding-bottom: 1000px;
  }

  .center {
    background-color: white;
    width: 85%;
    margin-bottom: -1000px;
    padding: 15px 15px 1000px;

    ::v-deep .ant-table-title {
      padding: 0;
    }
  }
}

.uploadImg {
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  background-image: url("../../../assets/add.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.upload_gutter {
  border: #DDDDDD 2px dashed;
  width: 200px;
  height: 100px;
}
</style>
